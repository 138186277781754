import zh from '@/locales/zh.json'
import en from '@/locales/en.json'

const Lang = {
    zh,
    en,
}
class searchFrom {
    constructor(type, lanuage = 0) {
        if (type === 'form') {
            this.roleId = []
        } else if (type === 'rule') {
            this.userName = [
                {
                    required: true,
                    message: Lang[lanuage === 1 ? `en` : `zh`].ple_ent_userName,
                    trigger: 'blur',
                },
            ]
        }
    }
}
export default searchFrom
