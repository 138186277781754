<template>
  <div class="wrap">
    <div class="search-container">
      <el-form ref="form" :model="form" label-width="80px">
        <el-row>
          <div class="tabs-container">
            <el-tabs
              class="taps-card"
              v-model="form.orderType"
              @tab-click="searchEvent"
            >
              <el-tab-pane label="全部" name="0"></el-tab-pane>
              <el-tab-pane label="预约" name="1"></el-tab-pane>
              <el-tab-pane label="购票" name="2"></el-tab-pane>
              <el-tab-pane label="课程" name="3"></el-tab-pane>
              <el-tab-pane label="赛事" name="4"></el-tab-pane>
              <el-tab-pane label="会员卡" name="6"></el-tab-pane>
              <el-tab-pane label="活动" name="7"></el-tab-pane>
              <!-- 5---视频课程 -->
            </el-tabs>
          </div></el-row
        >
        <el-row>
          <el-col :span="6">
            <el-form-item label="订单号:">
              <el-input
                clearable
                placeholder="请输入订单号"
                v-model="form.orderNo"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="客户姓名:">
              <el-input
                clearable
                placeholder="请输入客户姓名"
                v-model="form.searchCon"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="下单时间:">
              <el-date-picker
                style="width: 100%"
                clearable
                v-model="range"
                type="daterange"
                format="yyyy - MM - dd"
                value-format="yyyy-MM-dd"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="场馆:">
              <el-input
                clearable
                placeholder="请输入场馆名称"
                v-model="form.venueName"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="运动项目:">
              <el-select
                placeholder="请选择"
                clearable
                v-model="form.sportsProjectCode"
                style="width: 100%"
              >
                <el-option
                  v-for="item in DROPDOWNBOX.VENUE_MAIN_DIRECTION"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="订单来源:">
              <el-select
                placeholder="请选择"
                clearable
                v-model="form.orderFrom"
                style="width: 100%"
              >
                <el-option
                  v-for="item in orderList"
                  :key="item.value"
                  :label="item.lable"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="支付类型:">
              <el-select
                placeholder="请选择"
                clearable
                v-model="form.payType"
                style="width: 100%"
              >
                <el-option
                  v-for="item in payTypeList"
                  :key="item.value"
                  :label="item.lable"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6" style="text-align: right">
            <el-button @click="searchEvent" class="search-button">{{
              $t("search")
            }}</el-button>
            <el-button @click="cleanForm" class="clean-button">{{
              $t("clean")
            }}</el-button>
          </el-col>
        </el-row>
        <el-row class="search-statue">
          <div
            style="
              display: inline-block;
              color: #606266;
              margin-left: 10px;
              margin-right: 10px;
              font-size: 14px;
            "
          >
            订单状态
          </div>
          <div
            style="display: inline-block; margin: 0 10px"
            v-for="(item, index) in statusData"
            :key="index"
          >
            <el-button
              style="border: 1px solid #439bff; background: #439bff"
              :class="{ unchose: form.orderStatusText !== item }"
              @click="tabClick(index)"
              round
              >{{ item }}</el-button
            >
          </div>
        </el-row>
      </el-form>
    </div>
    <div class="table-container">
      <!-- 1-预约-and-2-购票 -->
      <el-table
        v-loading="pictLoading"
        element-loading-text="数据正在加载中"
        element-loading-spinner="el-icon-loading"
        :data="tableData"
        :height="tableHeight - 90"
        style="width: 100%"
        border
        :key="form.orderType === '1' ? '预约' : '购票'"
      >
        <el-table-column type="index" align="center" label="序号" width="50">
        </el-table-column>
        <el-table-column
          prop="orderNum"
          align="center"
          label="订单号"
          min-width="95"
        >
        </el-table-column>
        <el-table-column
          prop="customNam"
          align="center"
          label="客户姓名"
          min-width="95"
        >
        </el-table-column>
        <el-table-column
          prop="customPhone"
          align="center"
          label="联系电话"
          min-width="200"
        >
        </el-table-column>
        <el-table-column
          v-if="form.amountType === 0"
          prop="entityBaseInfoName"
          align="center"
          label="主体名称"
          min-width="200"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="venueName"
          label="场馆"
          min-width="170"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="sportsProjectName"
          min-width="177"
          label="运动项目"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="orderType"
          label="订单类型"
          min-width="100"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="originaPice"
          label="订单金额"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="payType"
          min-width="100"
          label="支付类型"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.payType | payTypeFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="memberCardPayAmount"
          min-width="100"
          label="卡付金额"
        >
        </el-table-column>
        <el-table-column
          v-if="false"
          align="center"
          prop="couponPrice"
          min-width="100"
          :sort-method="(a, b) => sortMoney(a, b, 'couponPrice')"
          label="优惠金额"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="realPay"
          min-width="120"
          label="实付金额"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="settlementRateAmount"
          show-overflow-tooltip
          label="费率"
          min-width="130"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="settlementAmount"
          show-overflow-tooltip
          label="结算金额"
          min-width="130"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="settlementDateTime"
          show-overflow-tooltip
          label="下单时间"
          min-width="130"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="orderStatus"
          label="订单状态"
          min-width="130"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.orderStatus === '待使用'"
              style="color: #eaa92e"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '待支付'"
              style="color: #d2514b"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '已完成'"
              style="color: #439bff"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '已取消'"
              style="color: #5f5f5f"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '待评价'"
              style="color: #47dbc6"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '已退款'"
              style="color: rgb(62, 103, 174)"
              >{{ scope.row.orderStatus }}</span
            >
            <span
              v-if="scope.row.orderStatus === '已取消(逾期)'"
              style="color: #5f5f5f"
              >{{ scope.row.orderStatus }}</span
            >
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="orderTicketNo"
          show-overflow-tooltip
          label="二维码"
          min-width="200"
        >
        </el-table-column>
        <el-table-column align="center" width="150" :label="$t('option')">
          <template slot-scope="scope">
            <el-button
              style="font-size: 16px"
              class="btn-detail"
              size="mini"
              @click="changeAddDialog(true, true, scope.row)"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="sumClass">
      <div class="left">
        <span class="orderNum">总计{{ form.total }}个订单</span>
      </div>
    </div>
    <div class="page-wrapper">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="form.total"
      >
      </el-pagination>
    </div>
    <add-dialog
      ref="addDialog"
      :Language="Language"
      :show="addDialog.visible"
      :isEdit="addDialog.isEdit"
      @closeDialog="changeAddDialog"
    ></add-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import apis from "@/apis";
import mixin from "@/mixins/index";
import OrderRecordSearchClass from "./orderRecordSearchClass";
import addDialog from "./dialog/addNewDialog.vue";

export default {
  components: {
    addDialog,
  },
  mixins: [mixin],
  data() {
    return {
      form: new OrderRecordSearchClass("form"), // 实例化一个表单的变量
      tableData: [],
      pictLoading: true,
      addDialog: {
        visible: false,
        isEdit: false,
      },
      statusData: ["全部", "待支付", "已支付", "已取消", "已退款"],
      range: [],
      confirmDialog: {
        visible: false,
        type: 1,
        text: "",
        data: {},
        sureDisabled: false,
      },
      sportList: [],
      orderList: [
        {
          lable: "全部",
          value: "0",
        },
        {
          lable: "线上-小程序",
          value: "1",
        },
        {
          lable: "线下-现场开单",
          value: "2",
        },
      ],
      payTypeList: [
        {
          lable: "微信",
          value: "1",
        },
        {
          lable: "线下-现金",
          value: "2",
        },
        {
          lable: "会员卡",
          value: "3",
        },
        {
          lable: "线下-支付宝",
          value: "4",
        },
        {
          lable: "线下-微信",
          value: "5",
        },
        {
          lable: "线下-刷卡",
          value: "6",
        },
      ],
    };
  },
  filters: {
    payTypeFilter(status) {
      const statusMap = {
        1: "微信",
        5: "线下-微信",
        3: "会员卡付",
        4: "线下-支付宝",
        2: "线下-现金",
        6: "线下-刷卡",
      };
      return statusMap[status];
    },
  },
  computed: {
    ...mapState(["Language", "venueId", "DROPDOWNBOX"]),
  },
  watch: {
    $route() {
      this.initData();
    },
  },
  activated() {
    this.form.merchantAccountId = this.$route.query.id;
    this.form.amountType = this.$route.query.type;
  },
  mounted() {},
  methods: {
    initData() {
      if (
        this.$route.name === "availableDetail" || this.$route.name === "freezeDetail" || this.$route.name === "fundDetail"
      ) {
        this.form.merchantAccountId = this.$route.query.id;
        this.form.amountType = this.$route.query.type;
        this.searchData();
      }
    },
    getSportList(id) {
      this.$http
        .get(`${apis.getSportsProjectListById}?id=${id}`)
        .then((res) => {
          if (res.data.code === 0) {
            this.sportList = res.data.rows;
          }
        });
    },
    /**
     * @function 搜索方法
     */
    searchData() {
      if (this.range) {
        if (this.range.length) {
          [this.form.orderStTime, this.form.orderEndTime] = this.range;
        }
      } else {
        this.form.orderStTime = "";
        this.form.orderEndTime = "";
      }
      this.pictLoading = true;
      this.$http
        .post(apis.queryOrderManagementNewList, this.form)
        .then((res) => {
          if (res.data.code === 0) {
            this.tableData = res.data.rows;
            this.form.total = res.data.total;
            this.pictLoading = false;
          } else if (res.data.code === 500) {
            this.tableData = [];
            this.form.total = 0;
          }
        });
    },
    /**
     * @function tab切换
     */
    tabClick(index) {
      if (index === 0) {
        this.form.searchStatusType = "";
      } else if (index === 1) {
        this.form.searchStatusType = "1";
      } else if (index === 2) {
        this.form.searchStatusType = "2";
      } else if (index === 3) {
        this.form.searchStatusType = "3";
      } else {
        this.form.searchStatusType = "4";
      }
      this.form.orderStatusText = this.statusData[index];
      this.searchData();
    },
    /**
     * @function 清空搜索栏
     */
    cleanForm() {
      this.form = new OrderRecordSearchClass("form");
      this.range = "";
      this.form.merchantAccountId = this.$route.query.id;
      this.form.amountType = this.$route.query.type;
      this.$nextTick(() => {
        this.searchEvent();
      });
    },
    /**
     * @function 改变新增/修改弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Boolean} isEdit 是否是修改弹窗，如果是则要调用接口，进行回显
     * @param {Object} data 修改回显时，传给后端的值
     */
    changeAddDialog(dialogStatus, isEdit = false, data) {
      if (dialogStatus) {
        // this.$refs['addDialog'].getRoleData()
        this.addDialog.isEdit = isEdit;
        this.$refs["addDialog"].getDataQuery(data, isEdit);
        // 这么写才不会影响最后的初始化，如果赋值完直接打开，会影响最后的初始化
      } else {
        // this.searchEvent()
      }
      this.$nextTick(() => {
        this.addDialog.visible = dialogStatus;
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
.tabs-container {
  margin: 0 20px;
}
.unchose {
  background: #fff !important;
  color: #439bff !important;
}
.sumClass {
  background: rgba(238, 240, 244, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 15px;
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  // margin-bottom: 10px;
  .left {
  }
  .right {
  }
}
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
.page-wrapper {
  float: right;
  margin: 0 20px;
  margin-top: 10px;
}
.search-container .el-button {
  // background: #3E67AE !important;
}
.button-container {
  text-align: left;
  .default-btn {
    // float: left !important;
  }
}
.table-container {
  width: calc(100% - 30px);
  margin: 0px auto;
  thead {
    tr {
      th {
        background: #eef0f4 !important;
      }
    }
  }
}
.search-statue {
  .el-button {
    // background: #3E67AE !important;
  }
}
.expand .el-table__expand-column .cell {
  display: none;
}
</style>
